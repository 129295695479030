<template>
    <footer class="footer">
        <div class="wrapper">
            <h4 class="footer__moto">{{ $t('footer.moto')}}</h4>
            <div class="footer__social">
                <a href="https://twitter.com/Geshaaa91" title="Twitter Link" target="_blank" class="footer__social-item"><svg 
                        x="0px" y="0px" width="517.5px" height="516.5px"
                        viewBox="0 0 517.5 516.5" enable-background="new 0 0 517.5 516.5" >
                        <rect id="rect2987" x="0" fill="none" width="517.5" height="516.5" />
                        <g id="layer1_2_" transform="matrix(1.418485,0,0,1.418485,-507.62367,-651.3318)">
                            <path id="path5_1_" d="M682.787,553.008c-10.487,4.645-21.752,7.779-33.581,9.191c12.067-7.223,21.34-18.664,25.708-32.285
                            c-11.297,6.688-23.816,11.54-37.139,14.158c-10.659-11.345-25.862-18.43-42.677-18.43c-32.296,0-58.48,26.134-58.48,58.363
                            c0,4.573,0.518,9.025,1.517,13.302c-48.601-2.441-91.684-25.669-120.525-60.985c-5.035,8.62-7.918,18.642-7.918,29.345
                            c0,20.248,10.32,38.109,26.012,48.575c-9.581-0.305-18.596-2.928-26.485-7.298c-0.003,0.24-0.003,0.486-0.003,0.733
                            c0,28.275,20.159,51.862,46.906,57.232c-4.907,1.326-10.075,2.045-15.402,2.045c-3.773,0-7.433-0.376-11-1.05
                            c7.439,23.182,29.037,40.058,54.62,40.529c-20.008,15.656-45.223,24.987-72.626,24.987c-4.715,0-9.37-0.277-13.948-0.823
                            c25.884,16.566,56.62,26.229,89.641,26.229c107.555,0,166.375-88.93,166.375-166.052c0-2.536-0.059-5.051-0.167-7.556
                            C665.031,574.988,674.951,564.713,682.787,553.008L682.787,553.008L682.787,553.008z" />
                        </g>
                    </svg></a>
                <a href="https://www.linkedin.com/in/geshaa/" title="Linkedin Link"  target="_blank" class="footer__social-item"><svg
                       x="0px" y="0px" width="522px" height="522px"
                        viewBox="-5.5 -5.5 522 522" enable-background="new -5.5 -5.5 522 522" >
                        <g id="g5891">
                            <rect id="rect2987" x="-5.5" y="-5.5" fill="none" width="522" height="522" />
                            <g id="g9-1" transform="matrix(1.5537946,0,0,1.5537946,-140.87332,-132.64552)">
                                <rect id="rect11" x="127.185" y="199.062" width="56.272" height="169.265" />
                                <path id="path13-0" d="M155.701,117.446c-19.253,0-31.831,12.657-31.831,29.247c0,16.241,12.198,29.247,31.086,29.247h0.364
                                c19.62,0,31.833-13.006,31.833-29.247C186.789,130.103,174.942,117.446,155.701,117.446L155.701,117.446z" />
                                <path id="path15" d="M321.556,195.082c-29.849,0-43.22,16.415-50.706,27.945v-23.964h-56.258c0.746,15.877,0,169.264,0,169.264
                                h56.257v-94.529c0-5.06,0.364-10.117,1.855-13.733c4.06-10.104,13.324-20.573,28.866-20.573c20.35,0,28.501,15.528,28.501,38.273
                                v90.563h56.26v-97.065C386.331,219.269,358.573,195.082,321.556,195.082z" />
                            </g>
                        </g>
                    </svg></a>
                <a href="https://github.com/Geshaa" title="Github Link"  target="_blank" class="footer__social-item">
                    <svg enable-background="new 0 0 30 30" height="30px" id="Github" version="1.1" viewBox="0 0 30 30" width="30px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path clip-rule="evenodd" d="M19,30h-3.996h-4c0,0,0.011-2.372,0-4  c-5.473,1.178-7-3-7-3c-1-2-2-3-2-3c-2-1.188,0-1,0-1c2,0,3,2,3,2c1.755,2.981,4.878,2.501,6,2c0-1,0.438-2.512,1-3  C7.636,19.508,4,17,4,12s1.004-6,2.004-7C5.802,4.507,4.965,2.685,6.035,0C6.035,0,8,0,10,3c0.991-0.991,4-1,5.001-1  C16,2,19.009,2.009,20,3c2-3,3.969-3,3.969-3c1.07,2.685,0.233,4.507,0.031,5c1,1,2,2,2,7s-3.632,7.508-8,8c0.562,0.488,1,2.21,1,3  V30z" fill-rule="evenodd"/></svg></a>
                <a href="mailto:development@givanov.eu" title="Mail to GIvanov"  class="footer__social-item"><svg height="512px" style="enable-background:new 0 0 512 512;" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M67,148.7c11,5.8,163.8,89.1,169.5,92.1c5.7,3,11.5,4.4,20.5,4.4c9,0,14.8-1.4,20.5-4.4c5.7-3,158.5-86.3,169.5-92.1   c4.1-2.1,11-5.9,12.5-10.2c2.6-7.6-0.2-10.5-11.3-10.5H257H65.8c-11.1,0-13.9,3-11.3,10.5C56,142.9,62.9,146.6,67,148.7z"/><path d="M455.7,153.2c-8.2,4.2-81.8,56.6-130.5,88.1l82.2,92.5c2,2,2.9,4.4,1.8,5.6c-1.2,1.1-3.8,0.5-5.9-1.4l-98.6-83.2   c-14.9,9.6-25.4,16.2-27.2,17.2c-7.7,3.9-13.1,4.4-20.5,4.4c-7.4,0-12.8-0.5-20.5-4.4c-1.9-1-12.3-7.6-27.2-17.2l-98.6,83.2   c-2,2-4.7,2.6-5.9,1.4c-1.2-1.1-0.3-3.6,1.7-5.6l82.1-92.5c-48.7-31.5-123.1-83.9-131.3-88.1c-8.8-4.5-9.3,0.8-9.3,4.9   c0,4.1,0,205,0,205c0,9.3,13.7,20.9,23.5,20.9H257h185.5c9.8,0,21.5-11.7,21.5-20.9c0,0,0-201,0-205   C464,153.9,464.6,148.7,455.7,153.2z"/></g></svg>
                </a>
            </div>
            <h6 class="footer__copyright">{{ new Date().getFullYear()}} &copy; {{ $t('footer.copy')}}</h6>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'SiteFooter',
}
</script>

<style lang="scss">
    .footer {
        padding: 1.5rem 0 5rem 0;
        background-color: var(--main-akcent-color);
        text-align: center;
        font-size: 1.5rem;
        color: #d2e9ff;

        &__moto {
            text-transform: uppercase;
        }

        &__social {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem;
                border-radius: 50%;
                border: 1px solid #d2e9ff;
                width: 40px;
                height: 40px;
                padding: 8px;
                transition: all .3s ease;

                @media (min-width: 764px) {
                    width: 50px;
                    height: 50px;
                }

                @media (min-width: 1024px) {
                    &:hover {
                        background: #d2e9ff;
                        border-color: #d2e9ff;

                        svg {
                            fill: var(--main-akcent-color);
                        }
                    }
                }

                svg {
                    max-width: 100%;
                    max-height: 100%;
                    fill: #d2e9ff;
                }
            }
        }

        &__copyright {
            margin-bottom: 0;
        }
    }
</style>