<template>
    <section class="hero">
        <div class="hero__holder wrapper">
            <div>
                <h1 class="hero__title">{{ $t('hero.title') }}</h1>
                <h2 class="hero__subtitle">{{ $t('hero.subtitle') }}</h2>
            </div>
            <figure class="hero__avatar"><img src="../assets/avatar2.png" alt="G.Ivanov Avatar"></figure>
            <figure class="hero__pic"><img src="../assets/hero.png" alt=""></figure>
            <span class="hero__scroll-down" @click="scrollEl('.about')"></span>
        </div>
    </section>
</template>

<script>
import {smoothScroll} from '../App'

export default {
    name: 'HeroSection',
    methods: {
        scrollEl(target) {
            smoothScroll(target)
        }
    }
}
</script>

<style lang="scss">
    .hero {
        position: relative;
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;

        &__holder {
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            min-height: 100%;
            padding: 50px 0
        }

        &__title {
            font-size: 1.8rem;

            @media (min-width: 640px) {
                font-size: 2.4rem;
            }
        }

        &__subtitle {
            font-size: 1rem;

            @media (min-width: 640px) {
                font-size: 1.125rem;
            }
        }

        &__avatar {
            text-align: center;

            img {
                max-width: 10rem;
                border-radius: 50%;

                @media (min-width: 640px) {
                    max-width: 11.25rem;
                }
            }
        }

        &__pic {
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 767px) {
                max-width: 90%;
            }
        }

        &__scroll-down {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            width: 20px;
            height: 35px;
            border: 3px solid var(--main-akcent-color);
            border-radius: 50px;
            margin: auto;
            cursor: pointer;
            z-index: 20;

            &:before {
                position: absolute;
                top: 5px;
                left: 50%;
                content: '';
                width: 6px;
                height: 6px;
                margin-left: -3px;
                background-color: var(--main-akcent-color);
                border-radius: 100%;
                animation: scrollDownPoint 2s infinite;
            }
        }
    }

    @keyframes scrollDownPoint {
        0% {
            transform: translate(0, 0);
            opacity: 0; 
        }
        40% {
            opacity: 1;
        }
        80% {
            transform: translate(0, 20px);
            opacity: 0; 
        }
        100% {
            opacity: 0; 
        } 
    }
</style>
